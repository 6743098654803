.fourSteps {
  width: 860px;
  background-color: white;
  border: 10px solid var(--lightestgreen);
  border-radius: 10px;
  margin-top: -80px;
  padding-bottom: 80px;
  padding-top: 40px;
  overflow: hidden;
}

.fourSteps > p {
  text-align: center;
  padding: 60px 100px 60px 100px;
  line-height: 1.3;
}

.stepsItem__right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.stepsItem__left {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.stepsItem__imgOnLeft {
  position: relative;
  background-color: var(--lightestgreen);
  height: 330px;
  width: 330px;
  border-radius: 999px;
  margin-left: -50px;
  margin-right: 100px;
}

.stepsItem__imgOnRight {
  position: relative;
  background-color: var(--lightestgreen);
  height: 330px;
  width: 330px;
  border-radius: 999px;
  margin-right: -50px;
  margin-left: 100px;
}

.leafImg {
  width: 330px;
  padding: 50px;
  margin-left: -90px;
  margin-top: -30px;
}

.caterpillarImg {
  width: 335px;
  padding: 50px;
  margin-left: -70px;
  margin-top: -60px;
}

.cocoonImg {
  width: 290px;
  padding: 50px;
  margin-left: 0px;
  margin-top: -20px;
}

.butterflyImg {
  width: 315px;
  padding: 50px;
  margin-left: -110px;
  margin-top: -80px;
}

.stepsItem__text {
  padding: 40px;
  text-align: center;
  line-height: 1.3;
}

.stepsItem__text > p {
  padding-top: 12px;
}

.leafImgSm {
  display: none;
}
.caterpillarImgSm {
  display: none;
}
.cocoonImgSm {
  display: none;
}
.butterflyImgSm {
  display: none;
}

@media (max-width: 1050px) {
  .fourSteps {
    width: 760px;
    padding-bottom: 20px;
    padding-top: 0px;
  }

  .stepsItem__imgOnLeft {
    height: 280px;
    width: 280px;
    margin-left: -50px;
  }

  .stepsItem__imgOnRight {
    height: 280px;
    width: 280px;

    margin-right: -50px;
  }
  .leafImg {
    width: 270px;
    margin-left: -75px;
  }

  .caterpillarImg {
    width: 280px;
  }

  .cocoonImg {
    width: 240px;
    padding: 50px;
  }

  .butterflyImg {
    width: 270px;
    padding: 70px;
    margin-top: -95px;
  }
}

@media (max-width: 915px) {
  .fourSteps {
    width: 640px;
  }

  .stepsItem__imgOnLeft {
    margin-right: 50px;
  }

  .stepsItem__imgOnRight {
    margin-left: 50px;
  }
}

@media (max-width: 800px) {
  .fourSteps {
    width: 480px;
  }

  .fourSteps > p {
    text-align: center;
    padding: 60px 40px 60px 40px;
    line-height: 1.3;
  }

  .stepsItem__imgOnLeft {
    height: 220px;
    width: 220px;
    margin-right: 0px;
    margin-left: -30px;
  }

  .stepsItem__imgOnRight {
    margin-left: 0;
    height: 220px;
    width: 220px;
    margin-right: -30px;
  }
  .leafImg {
    width: 220px;
    margin-left: -77px;
  }

  .caterpillarImg {
    width: 240px;
  }

  .cocoonImg {
    width: 200px;
    padding: 50px;
    margin-left: -20px;
    margin-top: -35px;
  }

  .butterflyImg {
    width: 215px;
    padding: 90px;
    margin-top: -105px;
    margin-left: -115px;
    overflow: hidden;
  }
}

@media (max-width: 550px) {
  .fourSteps {
    width: 85%;
  }

  .fourSteps > p {
    text-align: center;
    padding: 40px 30px 20px 30px;
  }

  .stepsItem__right {
    flex-direction: column;
  }

  .stepsItem__left {
    flex-direction: column;
  }

  .stepsItem__imgOnLeft {
    margin-left: 0px;
  }

  .stepsItem__imgOnRight {
    margin-right: 0px;
  }
  .leafImg {
    display: none;
  }

  .caterpillarImg {
    display: none;
  }

  .cocoonImg {
    display: none;
  }

  .butterflyImg {
    display: none;
  }

  .leafImgSm {
    display: flex;
    height: 130px;
    margin-left: 53px;
    margin-top: 55px;
  }
  .caterpillarImgSm {
    display: flex;
    height: 140px;
    margin-left: 40px;
    margin-top: 45px;
  }
  .cocoonImgSm {
    display: flex;
    height: 220px;
    margin-left: 10px;
    margin-top: 0px;
  }
  .butterflyImgSm {
    display: flex;
    height: 180px;
    margin-left: 20px;
    margin-top: 25px;
  }
}

@media (max-width: 350px) {
  .stepsItem__imgOnLeft,
  .stepsItem__imgOnRight {
    height: 200px;
    width: 200px;
  }

  .leafImgSm {
    height: 115px;
  }
  .caterpillarImgSm {
    display: flex;
    height: 130px;
    margin-left: 35px;
    margin-top: 40px;
  }
  .cocoonImgSm {
    height: 200px;
    margin-left: 6px;
  }
  .butterflyImgSm {
    height: 160px;
  }
}
