:root {
  --container: 1300px;
}

a {
  color: inherit;
  text-decoration: inherit;
}

a,
p,
span,
div,
input,
textarea {
  font-family: "Roboto", sans-serif;
  color: var(--brown);
}

h1,
h2,
h3,
h4 {
  font-family: "Dekko", cursive;
  font-weight: 300;
}

h1 {
  font-size: 72px;

  letter-spacing: 0px;
}

h2 {
  font-size: 25px;
}

h3 {
  font-size: 22px;
  line-height: 1.3;
}

.container {
  max-width: var(--container);
}

@media (max-width: 1350px) {
  h1 {
    font-size: 67px;
  }
}

@media (max-width: 1050px) {
  h1 {
    font-size: 62px;
  }

  h2 {
    font-size: 23px;
  }

  h3 {
    font-size: 21px;
  }
}

@media (max-width: 800px) {
  h1 {
    font-size: 57px;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 52px;
  }
}

@media (max-width: 385px) {
  h1 {
    font-size: 47px;
  }
}

@media (max-width: 320px) {
  h1 {
    font-size: 42px;
  }
}
