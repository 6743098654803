.questions {
  width: 860px;
  background-color: white;
  border-radius: 10px;
  border: 10px solid var(--lightestgreen);
  margin-top: -80px;
  line-height: 1.3;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  flex-direction: column;
}

.question {
  padding: 25px 50px;
}

.question__question {
  cursor: pointer;
}

.question__question:hover {
  color: var(--lightbrown);
  transition: all 0.3s;
}

.noAnswer {
  line-height: 0;
  overflow: hidden;
  transition: all 0.3s;
}

.noAnswer > ul {
  height: 0;
}

.noAnswer > p > ul > li {
  height: 0;
}

.question__answer {
  transition: all 0.3s;
  height: fit-content;
  padding-top: 10px;
  padding-bottom: 10px;
}

.question__listItem::marker {
  font-family: "Dekko", cursive;
  font-weight: 300;
  font-size: 22px;
  line-height: 1.3px;
}

@media (max-width: 1050px) {
  .questions {
    width: 720px;
  }

  .question__listItem::marker {
    font-size: 21px;
  }
}

@media (max-width: 915px) {
  .questions {
    width: 640px;
  }
}

@media (max-width: 800px) {
  .questions {
    width: 440px;
  }
}

@media (max-width: 600px) {
  .questions {
    width: 75%;
    margin-bottom: 100px;
  }
}
