.concept {
  padding: 115px 40px 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--lightestgreen);
}

.concept__img {
  width: 100%;
  max-width: var(--container);
  height: 500px;
  object-fit: cover;
  object-position: center left;
  border-radius: 10px;
}

.concept__title {
  position: absolute;
  top: var(--fullscreenTop);
  padding: 100px 80px;
  background-color: rgb(251, 253, 225, 0.7);
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.concept__title > p {
  font-size: 20px;
  font-weight: 600;
  padding-top: 5px;
}

.concept__button {
  padding: 100px 0 150px 0;
}

@media (max-width: 1350px) {
  .concept__title {
    padding: 80px 60px;
  }
}

@media (max-width: 1050px) {
  .concept {
    padding-top: 95px;
  }
  .concept__title {
    top: var(--top1050);
  }
}

@media (max-width: 800px) {
  .concept__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .concept__title {
    position: relative;
    padding: 40px;
    margin-top: var(--marginTop800);
    margin-bottom: var(--marginBottom800);
    border-radius: 10px;
    text-align: center;
    width: fit-content;
    min-width: 60%;
  }
}

@media (max-width: 600px) {
  .concept {
    padding: 0;
  }

  .concept__top {
    padding-top: 75px;
  }

  .concept__img {
    object-position: bottom;
    border-radius: 0px;
  }
  .concept__title {
    padding: 40px 20px;
    min-width: 70%;
  }
}

@media (max-width: 450px) {
  .concept__title {
    padding: 40px 10px;
    min-width: 85%;
  }
}

@media (max-width: 315px) {
  .concept__title {
    padding: 40px 5px;
  }
}
