.promoBar {
  text-align: center;
  padding: 16px 0;
  background-color: var(--darkgreen);
  cursor: pointer;
}

.promoBar p {
  font-size: 22px;
  color: white;
}
