.signUpButton {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signUpButton__text {
  padding: 0 100px 30px 100px;
  text-align: center;
}

.signUpButton__noText {
  display: none;
}

.signUpButton > button {
  background-image: linear-gradient(
    to top,
    var(--darkgreen) 35%,
    var(--yellow) 100%,
    var(--darkgreen) 100%
  );
}
.signUpButton > button {
  width: 250px;
  border: none;
  outline: none;
  border-radius: 68px;
  padding: 12px 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  display: block;
  cursor: pointer;
}

.signUpButton > button:hover {
  transform: scale(1.1);
}

@media (max-width: 600px) {
  .signUpButton > button {
    width: 200px;
    font-size: 19px;
  }
  .signUpButton__text {
    padding: 0 15% 30px 15%;
  }
}

@media (max-width: 400px) {
  .signUpButton > button {
    width: 200px;
  }
  .signUpButton__text {
    padding: 0 10% 30px 10%;
  }
}

@media (max-width: 310px) {
  .signUpButton__text {
    padding: 0 5% 30px 5%;
  }
  .signUpButton > button {
    width: 180px;
  }
}
