.contact {
  background-color: var(--beige);
  padding-top: 115px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact__img {
  height: 500px;
  width: 90vw;
  max-width: var(--container);
  object-fit: cover;
  border-radius: 10px;
}

.contact__title {
  position: absolute;
  top: var(--fullscreenTop);
  padding: 100px;
  background-color: rgb(251, 253, 225, 0.7);
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.contact__title > p {
  font-size: 20px;
  font-weight: 600;
  padding-top: 5px;
}

@media (max-width: 1350px) {
  .contact__title {
    padding: 80px 60px;
  }
}

@media (max-width: 1050px) {
  .contact {
    padding-top: 95px;
  }
  .contact__title {
    top: var(--top1050);
  }
}

@media (max-width: 800px) {
  .contact__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .contact__title {
    position: relative;
    padding: 40px;
    margin-top: var(--marginTop800);
    margin-bottom: var(--contactMarginBottom800);
    border-radius: 10px;
    text-align: center;
    width: fit-content;
    min-width: 60%;
  }
}

@media (max-width: 600px) {
  .contact {
    padding: 0;
  }

  .contact__top {
    padding-top: 75px;
  }

  .contact__img {
    border-radius: 0px;
    width: unset;
  }
  .contact__title {
    padding: 40px 20px;
    min-width: 70%;
  }
}

@media (max-width: 450px) {
  .contact__title {
    padding: 40px 10px;
    min-width: 85%;
  }
}

@media (max-width: 315px) {
  .contact__title {
    padding: 40px 5px;
  }
}
