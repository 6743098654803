.home {
  background-color: var(--beige);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home__top {
  padding: 115px 40px 0 40px;
}

.juicerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.juicer {
  padding-top: 100px;
  width: 880px;
}
.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.item__img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  z-index: 0;
}
.item__title {
  position: absolute;
  top: 0%;
  left: 0%;
  padding: 100px 80px;
  background-color: rgb(251, 253, 225, 0.7);
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: var(--brown);
  z-index: 0;
}

.item__title > p {
  font-size: 20px;
  font-weight: 600;
  padding-top: 5px;
}

@media (max-width: 1350px) {
  .item__title {
    padding: 80px 60px;
  }
}

@media (max-width: 1050px) {
  .home__top {
    padding-top: 95px;
  }

  .juicer {
    width: 760px;
  }
}

@media (max-width: 915px) {
  .juicer {
    width: 680px;
  }
}
@media (max-width: 800px) {
  .item__title {
    position: relative;
    padding: 40px;
    margin-top: -400px;
    margin-bottom: 200px;
    border-radius: 10px;
    text-align: center;
    min-width: 60%;
  }

  .juicer {
    width: 440px;
  }
}

@media (max-width: 600px) {
  .home__top {
    padding: 75px 0 0 0;
  }

  .item__title {
    padding: 40px 20px;
    min-width: 70%;
  }

  .item__img {
    object-fit: cover;
    object-position: center;
    z-index: 0;
    border-radius: 0px;
  }
}

@media (max-width: 575px) {
  .juicer {
    width: 340px;
  }
}

@media (max-width: 500px) {
  .juicer {
    width: 330px;
  }
}

@media (max-width: 450px) {
  .item__title {
    padding: 40px 10px;
    min-width: 85%;
  }
}

@media (max-width: 400px) {
  .juicer {
    width: 300px;
  }
}

@media (max-width: 320px) {
  .juicer {
    width: 260px;
  }
}

@media (max-width: 315px) {
  .item__title {
    padding: 40px 5px;
  }
}
