.about {
  padding: 0 40px;
  background-color: var(--lightestgreen);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__top {
  padding-top: 115px;
}

.about__img {
  width: 100%;
  max-width: var(--container);
  height: 500px;
  object-fit: cover;
  border-radius: 10px;
}

.about__title {
  position: absolute;
  top: var(--fullscreenTop);
  padding: 100px 80px;
  background-color: rgb(251, 253, 225, 0.7);
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.about__title > p {
  font-size: 20px;
  font-weight: 600;
  padding-top: 5px;
}

.about__info {
  width: 860px;
  background-color: white;
  border-radius: 10px;
  border: 10px solid var(--beige);
  margin-top: -80px;
  line-height: 1.3;
}

.about__infoTop {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 60px 60px 0 60px;
}

.about__infoTopImg {
  width: 250px;
  border-radius: 10px;
  margin-left: 40px;
}

.about__infoImgSm {
  display: none;
  height: 200px;
  width: auto;
  border-radius: 10px;
  margin-bottom: 50px;
}

.about__infoBottom {
  padding: 20px 60px 40px 60px;
}

.about__button {
  padding-top: 100px;
  padding-bottom: 150px;
}

@media (max-width: 1350px) {
  .about__title {
    padding: 80px 60px;
  }
}

@media (max-width: 1050px) {
  .about__top {
    padding-top: 95px;
  }
  .about__title {
    top: var(--top1050);
  }
  .about__info {
    width: 720px;
  }

  .about__infoTopImg {
    display: none;
  }

  .about__infoImgSm {
    display: flex;
  }

  .about__infoTop {
    flex-direction: column;
    align-items: center;
    padding: 50px 60px 0 60px;
  }
  .about__infoBottom {
    padding: 0px 60px 40px 60px;
  }
}

@media (max-width: 915px) {
  .about__info {
    width: 640px;
  }
  .about__infoTop {
    padding: 40px 40px 0 40px;
  }
  .about__infoBottom {
    padding: 0px 40px 40px 40px;
  }
}

@media (max-width: 800px) {
  .about__info {
    width: 440px;
  }
  .about__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .about__title {
    position: relative;
    padding: 40px;
    margin-top: var(--marginTop800);
    margin-bottom: var(--marginBottom800);
    border-radius: 10px;
    text-align: center;
    width: fit-content;
    min-width: 60%;
  }
}

@media (max-width: 600px) {
  .about__info {
    width: 85%;
  }

  .about {
    padding: 0;
  }

  .about__top {
    padding-top: 75px;
  }

  .about__img {
    border-radius: 0px;
  }
  .about__title {
    padding: 40px 20px;
    min-width: 70%;
  }

  .about__infoTop {
    padding: 40px 30px 0 30px;
  }
  .about__infoBottom {
    padding: 0px 30px 40px 30px;
  }
}

@media (max-width: 450px) {
  .about__title {
    padding: 40px 10px;
    min-width: 85%;
  }
  .about__infoTop {
    padding: 60px 20px 0 20px;
  }
  .about__infoBottom {
    padding: 0px 20px 40px 20px;
  }
  .about__infoImgSm {
    height: 170px;
  }
}

@media (max-width: 315px) {
  .about__title {
    padding: 40px 5px;
  }
}
