* {
  margin: 0;
}

:root {
  /* colors */
  --darkgreen: #77dd76;
  --green: #a6eca8;
  --lightgreen: #d2fdbb;
  --lightestgreen: #e4fad7;
  --beige: #fbfde1;
  --yellow: #fdfd97;
  --brown: #7c7155;
  --lightbrown: #b4a57f;

  /* margins, etcs related to banner */
  --fullscreenTop: 173px;
  --top1050: 153px;
  --marginTop800: -533px;
  --marginBottom800: 353px;
  --contactMarginBottom800: 258px;
}

/* 
colours: 
darkgreen: #77DD76
mediumgreen: #A6ECA8 
lightgreen: #D2FDBB
beige: #FBFDE1
yellow: #FDFD97

textbrown: #7C7155
*/
