.menuContainer {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.menuBefore {
  width: 100%;
  position: absolute;
  top: 95px;
  transition: height 0.3s;
  color: transparent;
  height: 0px;
  z-index: 10;
}

.menu {
  position: absolute;
  top: 90px;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 350px;
  transition: height 0.3s;
}

#menuBeige {
  background-color: var(--beige);
}

#menuGreen {
  background-color: var(--lightestgreen);
}

.menu > h3 {
  padding: 15px 0;
  cursor: pointer;
}

.menu > h3:hover {
  color: var(--darkgreen);
  transition: all 0.3s;
}

@media (max-width: 600px) {
  .menuBefore,
  .menu {
    top: 70px;
  }
}
