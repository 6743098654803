.collage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 80px;
}

.collage__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 880px;
  background-color: white;
}

.collage__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.collage__leftBottom {
  display: flex;
}

.collage__right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.collage__bigSquare {
  width: 410px;
  height: auto;
  object-fit: cover;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.collage__smallRectangle {
  width: 195px;
  height: auto;
  object-fit: cover;
  margin: 10px 10px 20px 10px;
  border-radius: 10px;
}

.collage__smallSquare {
  width: 195px;
  height: 220px;
  object-fit: cover;
  margin: 10px;
  border-radius: 10px;
}

.collage__bigRectangle {
  width: 410px;
  height: 458px;
  object-fit: cover;
  margin: 10px;
  border-radius: 10px;
}

@media (max-width: 1050px) {
  .collage__container {
    width: 760px;
  }
  .collage__bigSquare {
    width: 350px;
    height: 410px;
  }

  .collage__smallRectangle {
    width: 165px;
  }

  .collage__smallSquare {
    width: 165px;
  }

  .collage__bigRectangle {
    width: 350px;
    height: 430px;
  }
}

@media (max-width: 915px) {
  .collage__container {
    width: 680px;
  }
  .collage__bigSquare {
    width: 310px;
    height: 310px;
  }

  .collage__smallRectangle {
    width: 145px;
  }

  .collage__smallSquare {
    width: 145px;
  }

  .collage__bigRectangle {
    width: 310px;
    height: 305px;
  }
}

@media (max-width: 800px) {
  .collage__container {
    width: 440px;
    flex-direction: column;
    align-items: center;
  }
  .collage__bigSquare {
    width: 400px;
    height: auto;
  }

  .collage__smallRectangle {
    width: 190px;
    height: auto;
  }

  .collage__smallSquare {
    width: 190px;
    height: auto;
  }

  .collage__bigRectangle {
    width: 400px;
    height: auto;
  }
  .collage__right {
    padding-bottom: 10px;
  }
}

@media (max-width: 575px) {
  .collage__container {
    width: 340px;
  }
  .collage__bigSquare {
    width: 300px;
  }

  .collage__smallRectangle {
    width: 140px;
  }

  .collage__smallSquare {
    width: 140px;
  }

  .collage__bigRectangle {
    width: 300px;
  }
}

@media (max-width: 500px) {
  .collage__container {
    width: 330px;
  }
  .collage__bigSquare {
    width: 290px;
  }

  .collage__smallRectangle {
    width: 135px;
  }

  .collage__smallSquare {
    width: 135px;
  }

  .collage__bigRectangle {
    width: 290px;
  }
}

@media (max-width: 400px) {
  .collage__container {
    width: 300px;
  }
  .collage__bigSquare {
    width: 260px;
  }

  .collage__smallRectangle {
    width: 120px;
  }

  .collage__smallSquare {
    width: 120px;
  }

  .collage__bigRectangle {
    width: 260px;
  }
}

@media (max-width: 320px) {
  .collage__container {
    width: 260px;
  }
  .collage__bigSquare {
    width: 220px;
  }

  .collage__smallRectangle {
    width: 100px;
  }

  .collage__smallSquare {
    width: 100px;
  }

  .collage__bigRectangle {
    width: 220px;
  }
}
