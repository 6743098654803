.header {
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 10px 0;
}

.header__logo {
  height: 90px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
}

#nav-icon {
  display: none;
}

.header__left {
  display: flex;
  align-items: center;
}

.header__options {
  display: flex;
}

.header__options > p {
  padding: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.header__options > p:hover {
  color: var(--darkgreen);
  transition: 0.3s;
}

.header__right {
  padding-right: 30px;
  display: flex;
}

.header__flag {
  padding: 10px;
  cursor: pointer;
  height: 30px;
  width: auto;
}

.header__flag:hover {
  transform: scale(1.1);
}

@media (max-width: 1050px) {
  .header__logo {
    height: 70px;
  }
  .header__options > p {
    padding: 10px;
  }
}

@media (max-width: 800px) {
  .header__options {
    display: none;
  }
  #nav-icon {
    display: unset;
    width: 25px;
    height: 20px;
    position: relative;
    margin: 14px 10px 0 20px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: var(--brown);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  #nav-icon span:nth-child(1) {
    top: 0px;
  }

  #nav-icon span:nth-child(2),
  #nav-icon3 span:nth-child(3) {
    top: 8px;
  }

  #nav-icon span:nth-child(4) {
    top: 16px;
  }

  #nav-icon.open span:nth-child(1) {
    top: 0px;
    width: 0%;
    left: 50%;
  }

  #nav-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg) translate(0px, 2px);
  }

  #nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg) translate(-7.5px, 5px);
  }

  #nav-icon.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
}

@media (max-width: 600px) {
  .header {
    padding: 5px 0;
  }

  .header__logo {
    height: 65px;
    padding-left: 5px;
  }

  .header__right {
    padding-right: 0px;
  }

  .header__flag {
    padding: 5px;
    cursor: pointer;
    height: 30px;
  }

  #nav-icon {
    margin: 10px 15px 0 20px;
  }
}
