.contactForm {
  width: 880px;
  background-color: white;
  margin-top: -80px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 60px 0;
}

.contactFormContainer {
  width: 500px;
}

.contactFormContainer > h2 {
  text-align: center;
  font-size: 35px;
}

.contactForm__text {
  text-align: center;
  padding: 15px 0;
}

.contactForm__warning {
  text-align: center;
  color: red;
  padding: 20px 0;
}

.contactForm__input {
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 18px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 16px;
}

.lightGreenBackground {
  background-color: var(--lightestgreen);
}

.greenBackground {
  background-color: var(--lightgreen);
}

.darkGreenBackground {
  background-color: var(--green);
  height: 130px;
  text-align: start;
}

.contactForm__disclaimer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0px 30px 0px;
}

.addPadding {
  padding-left: 10px;
}

.contactForm__confirmation {
  position: fixed;
  top: 40%;
}

.botCheck {
  text-align: center;
  padding: 20px 0;
}

.botCheck__images {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.botCheck__images > img {
  height: 80px;
  margin: 10px;
  cursor: pointer;
}

.botCheck__images > img:hover {
  transform: scale(1.1);
}

.botCheck__clicked {
  transform: scale(1.2);
  background-color: var(--green);
  border-radius: 10px;
}

@media (max-width: 1050px) {
  .contactForm {
    width: 720px;
  }

  .contactFormContainer > h2 {
    font-size: 30px;
  }
}

@media (max-width: 915px) {
  .contactForm {
    width: 640px;
    padding: 50px 0;
  }

  .contactFormContainer {
    width: 400px;
  }

  .contactFormContainer > h2 {
    font-size: 25px;
  }

  .contactForm__text {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .contactForm__input {
    padding: 15px 5%;
    width: 90%;
  }
}

@media (max-width: 800px) {
  .contactForm {
    width: 480px;
    padding: 40px 0;
    margin-top: 20px;
  }

  .contactFormContainer {
    width: 380px;
  }
}

@media (max-width: 550px) {
  .contactForm {
    width: 90%;
    padding: 40px 0;
    margin-top: 20px;
  }

  .contactFormContainer {
    width: 85%;
  }

  .botCheck__images > img {
    height: 60px;
  }
}
