.footer {
  background-color: var(--brown);
  color: var(--beige);
  padding: 5px 20px;
}

.footer__icons {
  display: flex;
  color: var(--beige);
  padding-left: 13px;
}

.footer__icon {
  padding: 5px;
  color: inherit;
  cursor: pointer;
}

.footer__icon:hover {
  color: var(--darkgreen);
}

.footer > p {
  font-size: 12px;
  padding-bottom: 10px;
  color: var(--beige);
}

.footer > div {
  font-size: 12px;
  color: var(--beige);
}

a {
  color: var(--beige);
  font-size: 12px;
}

a:hover {
  text-decoration: underline;
}

.footer__privacyAndTerms {
  padding: 10px 10px 10px 0;
  display: flex;
}
