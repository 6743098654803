.stampsBig {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
  flex-wrap: wrap;
}

.stampsBig img {
  height: 130px;
  width: auto;
  padding: 0 20px 50px 20px;
}

.stampsSmall {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.stampsSmall img {
  height: 100px;
  width: auto;
  padding: 0 30px 40px 30px;
}

@media (max-width: 600px) {
  .stampsBig {
    flex-direction: column;
    align-items: center;
  }
  .stampsBig img {
    object-fit: contain;
    padding: 15px;
  }
}
