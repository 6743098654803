.boardItem__white {
  background-color: white;
  width: 160px;
  text-align: center;
  align-items: center;
  padding: 0px 30px;
  height: 350px;
}

.boardItem__lightgreen {
  background-color: var(--lightgreen);
  width: 160px;
  text-align: center;
  padding: 0 30px;
}

.boardItem__green {
  background-color: var(--green);
  width: 160px;
  text-align: center;
  padding: 0 30px;
}

.boardItem__img {
  height: 50px;
  width: auto;
  padding-top: 50px;
  padding-bottom: 20px;
}

.boardItem__title {
  padding-bottom: 20px;
}

@media (max-width: 1050px) {
  .boardItem__white,
  .boardItem__green,
  .boardItem__lightgreen {
    width: 160px;
    height: 380px;
    padding: 0 15px;
  }
  .boardItem__img {
    height: 50px;
    width: auto;
    padding-top: 40px;
    padding-bottom: 20px;
  }
}

@media (max-width: 915px) {
  .boardItem__white,
  .boardItem__green,
  .boardItem__lightgreen {
    width: 110px;
    height: 400px;
  }
  .boardItem__img {
    padding-top: 30px;
    padding-bottom: 7px;
  }
  .boardItem__title {
    padding-bottom: 7px;
  }
}

@media (max-width: 800px) {
  .boardItem__white,
  .boardItem__green,
  .boardItem__lightgreen {
    width: 160px;
    height: 350px;
  }
  .boardItem__img {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .boardItem__title {
    padding-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .boardItem__white,
  .boardItem__green,
  .boardItem__lightgreen {
    width: 110px;
    height: 400px;
  }
  .boardItem__img {
    padding-top: 30px;
    padding-bottom: 7px;
  }
  .boardItem__title {
    padding-bottom: 7px;
  }
}

@media (max-width: 500px) {
  .boardItem__white,
  .boardItem__green,
  .boardItem__lightgreen {
    width: 270px;
    padding: 0px 30px 50px 30px;
    height: fit-content;
  }
  .boardItem__img {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .boardItem__title {
    padding-bottom: 20px;
  }
}

@media (max-width: 400px) {
  .boardItem__white,
  .boardItem__green,
  .boardItem__lightgreen {
    width: 240px;
  }
}

@media (max-width: 320px) {
  .boardItem__white,
  .boardItem__green,
  .boardItem__lightgreen {
    width: 200px;
  }
}
