.admin__header {
  display: flex;
  height: 60px;
  justify-content: space-around;
  align-items: center;
  background-color: var(--beige);
}

.admin__option {
  cursor: pointer;
}

.admin__option:hover {
  color: var(--darkgreen);
  transition: all 0.3s;
}

.admin__body {
  background-color: var(--lightestgreen);
  padding: 50px 0;
  min-height: 100%;
}

.adminAddNew {
  font-size: 20px;
  text-align: center;
  cursor: pointer;
}

.adminTitle {
  text-align: center;
  padding-bottom: 20px;
}

.adminItemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.adminItemTitle {
  padding-bottom: 10px;
}

.adminItem {
  width: 85%;
  max-width: 600px;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  background-color: var(--green);
}

.adminButtons {
  display: flex;
}

.adminEdit,
.adminDelete,
.adminButton {
  background-color: var(--lightbrown);
  color: white;
  padding: 3px 10px;
  margin: 10px 20px 0 0;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.adminLang {
  background-color: var(--lightbrown);
  color: white;
  padding: 3px 10px;
  margin: 10px 20px 20px 0;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.adminAddNewQuestion {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.adminInput {
  width: 85%;
  max-width: 600px;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  background-color: white;
}

.adminInput > textarea {
  outline: none;
  border: none;
  width: 100%;
  height: fit-content;
  padding-top: 10px;
  font-size: 16px;
  min-height: 400px;
}
