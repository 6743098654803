.board {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -80px;
  z-index: 10;
  padding-bottom: 100px;
}

.board__top {
  display: flex;
  z-index: 5;
}

.board__topLeft {
  display: flex;
}

.board__topRight {
  display: flex;
}

.board__bottom {
  display: flex;
}

.board__bottomLeft {
  display: flex;
}

.board__bottomRight {
  display: flex;
}

@media (max-width: 800px) {
  .board__top {
    flex-direction: column;
  }
  .board__bottom {
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .board__topLeft {
    flex-direction: column;
  }
  .board__bottomLeft {
    flex-direction: column;
  }
  .board__topRight {
    flex-direction: column;
  }
  .board__bottomRight {
    flex-direction: column;
  }
}
