.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--beige);
  min-height: 100vh;
}

.login__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.login__email,
.login__password {
  width: 250px;
  height: 50px;
  outline: none;
  border-radius: 10px;
  border: none;
  margin-bottom: 20px;
  padding: 0 20px;
  font-size: 18px;
}

.login__email {
  background-color: var(--darkgreen);
}

.login__password {
  background-color: var(--darkgreen);
}

.login__form > button {
  background-image: linear-gradient(
    to top,
    var(--darkgreen) 35%,
    var(--yellow) 100%,
    var(--darkgreen) 100%
  );
}
.login__form > button {
  width: 150px;
  border: none;
  outline: none;
  border-radius: 68px;
  padding: 12px 0;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  display: block;
  cursor: pointer;
  margin-top: 10px;
}

.login__form > button:hover {
  transform: scale(1.1);
}
