.confirmation {
  background-color: rgb(255, 255, 255, 0.9) !important;
  padding: 30px 40px;
  width: 300px;
  text-align: center;
  border-radius: 10px;
}

.confirmation > p {
  padding: 20px 0;
}

.confirmation > button {
  border: 1.5px solid var(--brown);
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  padding: 5px 20px;
  background-color: transparent;
  font-size: 14px;
  color: var(--brown);
  border-radius: 10px;
}

.confirmation > button:hover {
  background-color: var(--brown);
  color: white;
  transition: all 0.3s;
}
