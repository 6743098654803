* {
  margin: 0;
}

:root {
  /* colors */
  --darkgreen: #77dd76;
  --green: #a6eca8;
  --lightgreen: #d2fdbb;
  --lightestgreen: #e4fad7;
  --beige: #fbfde1;
  --yellow: #fdfd97;
  --brown: #7c7155;
  --lightbrown: #b4a57f;

  /* margins, etcs related to banner */
  --fullscreenTop: 173px;
  --top1050: 153px;
  --marginTop800: -533px;
  --marginBottom800: 353px;
  --contactMarginBottom800: 258px;
}

/* 
colours: 
darkgreen: #77DD76
mediumgreen: #A6ECA8 
lightgreen: #D2FDBB
beige: #FBFDE1
yellow: #FDFD97

textbrown: #7C7155
*/

:root {
  --container: 1300px;
}

a {
  color: inherit;
  text-decoration: inherit;
}

a,
p,
span,
div,
input,
textarea {
  font-family: "Roboto", sans-serif;
  color: var(--brown);
}

h1,
h2,
h3,
h4 {
  font-family: "Dekko", cursive;
  font-weight: 300;
}

h1 {
  font-size: 72px;

  letter-spacing: 0px;
}

h2 {
  font-size: 25px;
}

h3 {
  font-size: 22px;
  line-height: 1.3;
}

.container {
  max-width: 1300px;
  max-width: var(--container);
}

@media (max-width: 1350px) {
  h1 {
    font-size: 67px;
  }
}

@media (max-width: 1050px) {
  h1 {
    font-size: 62px;
  }

  h2 {
    font-size: 23px;
  }

  h3 {
    font-size: 21px;
  }
}

@media (max-width: 800px) {
  h1 {
    font-size: 57px;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 52px;
  }
}

@media (max-width: 385px) {
  h1 {
    font-size: 47px;
  }
}

@media (max-width: 320px) {
  h1 {
    font-size: 42px;
  }
}

.home {
  background-color: var(--beige);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home__top {
  padding: 115px 40px 0 40px;
}

.juicerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.juicer {
  padding-top: 100px;
  width: 880px;
}
.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.item__img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  z-index: 0;
}
.item__title {
  position: absolute;
  top: 0%;
  left: 0%;
  padding: 100px 80px;
  background-color: rgb(251, 253, 225, 0.7);
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: var(--brown);
  z-index: 0;
}

.item__title > p {
  font-size: 20px;
  font-weight: 600;
  padding-top: 5px;
}

@media (max-width: 1350px) {
  .item__title {
    padding: 80px 60px;
  }
}

@media (max-width: 1050px) {
  .home__top {
    padding-top: 95px;
  }

  .juicer {
    width: 760px;
  }
}

@media (max-width: 915px) {
  .juicer {
    width: 680px;
  }
}
@media (max-width: 800px) {
  .item__title {
    position: relative;
    padding: 40px;
    margin-top: -400px;
    margin-bottom: 200px;
    border-radius: 10px;
    text-align: center;
    min-width: 60%;
  }

  .juicer {
    width: 440px;
  }
}

@media (max-width: 600px) {
  .home__top {
    padding: 75px 0 0 0;
  }

  .item__title {
    padding: 40px 20px;
    min-width: 70%;
  }

  .item__img {
    object-fit: cover;
    object-position: center;
    z-index: 0;
    border-radius: 0px;
  }
}

@media (max-width: 575px) {
  .juicer {
    width: 340px;
  }
}

@media (max-width: 500px) {
  .juicer {
    width: 330px;
  }
}

@media (max-width: 450px) {
  .item__title {
    padding: 40px 10px;
    min-width: 85%;
  }
}

@media (max-width: 400px) {
  .juicer {
    width: 300px;
  }
}

@media (max-width: 320px) {
  .juicer {
    width: 260px;
  }
}

@media (max-width: 315px) {
  .item__title {
    padding: 40px 5px;
  }
}

.signUpButton {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signUpButton__text {
  padding: 0 100px 30px 100px;
  text-align: center;
}

.signUpButton__noText {
  display: none;
}

.signUpButton > button {
  background-image: linear-gradient(
    to top,
    var(--darkgreen) 35%,
    var(--yellow) 100%,
    var(--darkgreen) 100%
  );
}
.signUpButton > button {
  width: 250px;
  border: none;
  outline: none;
  border-radius: 68px;
  padding: 12px 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  display: block;
  cursor: pointer;
}

.signUpButton > button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@media (max-width: 600px) {
  .signUpButton > button {
    width: 200px;
    font-size: 19px;
  }
  .signUpButton__text {
    padding: 0 15% 30px 15%;
  }
}

@media (max-width: 400px) {
  .signUpButton > button {
    width: 200px;
  }
  .signUpButton__text {
    padding: 0 10% 30px 10%;
  }
}

@media (max-width: 310px) {
  .signUpButton__text {
    padding: 0 5% 30px 5%;
  }
  .signUpButton > button {
    width: 180px;
  }
}

.collage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 80px;
}

.collage__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 880px;
  background-color: white;
}

.collage__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.collage__leftBottom {
  display: flex;
}

.collage__right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.collage__bigSquare {
  width: 410px;
  height: auto;
  object-fit: cover;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.collage__smallRectangle {
  width: 195px;
  height: auto;
  object-fit: cover;
  margin: 10px 10px 20px 10px;
  border-radius: 10px;
}

.collage__smallSquare {
  width: 195px;
  height: 220px;
  object-fit: cover;
  margin: 10px;
  border-radius: 10px;
}

.collage__bigRectangle {
  width: 410px;
  height: 458px;
  object-fit: cover;
  margin: 10px;
  border-radius: 10px;
}

@media (max-width: 1050px) {
  .collage__container {
    width: 760px;
  }
  .collage__bigSquare {
    width: 350px;
    height: 410px;
  }

  .collage__smallRectangle {
    width: 165px;
  }

  .collage__smallSquare {
    width: 165px;
  }

  .collage__bigRectangle {
    width: 350px;
    height: 430px;
  }
}

@media (max-width: 915px) {
  .collage__container {
    width: 680px;
  }
  .collage__bigSquare {
    width: 310px;
    height: 310px;
  }

  .collage__smallRectangle {
    width: 145px;
  }

  .collage__smallSquare {
    width: 145px;
  }

  .collage__bigRectangle {
    width: 310px;
    height: 305px;
  }
}

@media (max-width: 800px) {
  .collage__container {
    width: 440px;
    flex-direction: column;
    align-items: center;
  }
  .collage__bigSquare {
    width: 400px;
    height: auto;
  }

  .collage__smallRectangle {
    width: 190px;
    height: auto;
  }

  .collage__smallSquare {
    width: 190px;
    height: auto;
  }

  .collage__bigRectangle {
    width: 400px;
    height: auto;
  }
  .collage__right {
    padding-bottom: 10px;
  }
}

@media (max-width: 575px) {
  .collage__container {
    width: 340px;
  }
  .collage__bigSquare {
    width: 300px;
  }

  .collage__smallRectangle {
    width: 140px;
  }

  .collage__smallSquare {
    width: 140px;
  }

  .collage__bigRectangle {
    width: 300px;
  }
}

@media (max-width: 500px) {
  .collage__container {
    width: 330px;
  }
  .collage__bigSquare {
    width: 290px;
  }

  .collage__smallRectangle {
    width: 135px;
  }

  .collage__smallSquare {
    width: 135px;
  }

  .collage__bigRectangle {
    width: 290px;
  }
}

@media (max-width: 400px) {
  .collage__container {
    width: 300px;
  }
  .collage__bigSquare {
    width: 260px;
  }

  .collage__smallRectangle {
    width: 120px;
  }

  .collage__smallSquare {
    width: 120px;
  }

  .collage__bigRectangle {
    width: 260px;
  }
}

@media (max-width: 320px) {
  .collage__container {
    width: 260px;
  }
  .collage__bigSquare {
    width: 220px;
  }

  .collage__smallRectangle {
    width: 100px;
  }

  .collage__smallSquare {
    width: 100px;
  }

  .collage__bigRectangle {
    width: 220px;
  }
}

.boardItem__white {
  background-color: white;
  width: 160px;
  text-align: center;
  align-items: center;
  padding: 0px 30px;
  height: 350px;
}

.boardItem__lightgreen {
  background-color: var(--lightgreen);
  width: 160px;
  text-align: center;
  padding: 0 30px;
}

.boardItem__green {
  background-color: var(--green);
  width: 160px;
  text-align: center;
  padding: 0 30px;
}

.boardItem__img {
  height: 50px;
  width: auto;
  padding-top: 50px;
  padding-bottom: 20px;
}

.boardItem__title {
  padding-bottom: 20px;
}

@media (max-width: 1050px) {
  .boardItem__white,
  .boardItem__green,
  .boardItem__lightgreen {
    width: 160px;
    height: 380px;
    padding: 0 15px;
  }
  .boardItem__img {
    height: 50px;
    width: auto;
    padding-top: 40px;
    padding-bottom: 20px;
  }
}

@media (max-width: 915px) {
  .boardItem__white,
  .boardItem__green,
  .boardItem__lightgreen {
    width: 110px;
    height: 400px;
  }
  .boardItem__img {
    padding-top: 30px;
    padding-bottom: 7px;
  }
  .boardItem__title {
    padding-bottom: 7px;
  }
}

@media (max-width: 800px) {
  .boardItem__white,
  .boardItem__green,
  .boardItem__lightgreen {
    width: 160px;
    height: 350px;
  }
  .boardItem__img {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .boardItem__title {
    padding-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .boardItem__white,
  .boardItem__green,
  .boardItem__lightgreen {
    width: 110px;
    height: 400px;
  }
  .boardItem__img {
    padding-top: 30px;
    padding-bottom: 7px;
  }
  .boardItem__title {
    padding-bottom: 7px;
  }
}

@media (max-width: 500px) {
  .boardItem__white,
  .boardItem__green,
  .boardItem__lightgreen {
    width: 270px;
    padding: 0px 30px 50px 30px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .boardItem__img {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .boardItem__title {
    padding-bottom: 20px;
  }
}

@media (max-width: 400px) {
  .boardItem__white,
  .boardItem__green,
  .boardItem__lightgreen {
    width: 240px;
  }
}

@media (max-width: 320px) {
  .boardItem__white,
  .boardItem__green,
  .boardItem__lightgreen {
    width: 200px;
  }
}

.board {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -80px;
  z-index: 10;
  padding-bottom: 100px;
}

.board__top {
  display: flex;
  z-index: 5;
}

.board__topLeft {
  display: flex;
}

.board__topRight {
  display: flex;
}

.board__bottom {
  display: flex;
}

.board__bottomLeft {
  display: flex;
}

.board__bottomRight {
  display: flex;
}

@media (max-width: 800px) {
  .board__top {
    flex-direction: column;
  }
  .board__bottom {
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .board__topLeft {
    flex-direction: column;
  }
  .board__bottomLeft {
    flex-direction: column;
  }
  .board__topRight {
    flex-direction: column;
  }
  .board__bottomRight {
    flex-direction: column;
  }
}

.stampsBig {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
  flex-wrap: wrap;
}

.stampsBig img {
  height: 130px;
  width: auto;
  padding: 0 20px 50px 20px;
}

.stampsSmall {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.stampsSmall img {
  height: 100px;
  width: auto;
  padding: 0 30px 40px 30px;
}

@media (max-width: 600px) {
  .stampsBig {
    flex-direction: column;
    align-items: center;
  }
  .stampsBig img {
    object-fit: contain;
    padding: 15px;
  }
}

.header {
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 10px 0;
}

.header__logo {
  height: 90px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
}

#nav-icon {
  display: none;
}

.header__left {
  display: flex;
  align-items: center;
}

.header__options {
  display: flex;
}

.header__options > p {
  padding: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.header__options > p:hover {
  color: var(--darkgreen);
  transition: 0.3s;
}

.header__right {
  padding-right: 30px;
  display: flex;
}

.header__flag {
  padding: 10px;
  cursor: pointer;
  height: 30px;
  width: auto;
}

.header__flag:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@media (max-width: 1050px) {
  .header__logo {
    height: 70px;
  }
  .header__options > p {
    padding: 10px;
  }
}

@media (max-width: 800px) {
  .header__options {
    display: none;
  }
  #nav-icon {
    display: unset;
    width: 25px;
    height: 20px;
    position: relative;
    margin: 14px 10px 0 20px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: var(--brown);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  #nav-icon span:nth-child(1) {
    top: 0px;
  }

  #nav-icon span:nth-child(2),
  #nav-icon3 span:nth-child(3) {
    top: 8px;
  }

  #nav-icon span:nth-child(4) {
    top: 16px;
  }

  #nav-icon.open span:nth-child(1) {
    top: 0px;
    width: 0%;
    left: 50%;
  }

  #nav-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg) translate(0px, 2px);
  }

  #nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg) translate(-7.5px, 5px);
  }

  #nav-icon.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
}

@media (max-width: 600px) {
  .header {
    padding: 5px 0;
  }

  .header__logo {
    height: 65px;
    padding-left: 5px;
  }

  .header__right {
    padding-right: 0px;
  }

  .header__flag {
    padding: 5px;
    cursor: pointer;
    height: 30px;
  }

  #nav-icon {
    margin: 10px 15px 0 20px;
  }
}

.menuContainer {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.menuBefore {
  width: 100%;
  position: absolute;
  top: 95px;
  transition: height 0.3s;
  color: transparent;
  height: 0px;
  z-index: 10;
}

.menu {
  position: absolute;
  top: 90px;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 350px;
  transition: height 0.3s;
}

#menuBeige {
  background-color: var(--beige);
}

#menuGreen {
  background-color: var(--lightestgreen);
}

.menu > h3 {
  padding: 15px 0;
  cursor: pointer;
}

.menu > h3:hover {
  color: var(--darkgreen);
  transition: all 0.3s;
}

@media (max-width: 600px) {
  .menuBefore,
  .menu {
    top: 70px;
  }
}

.about {
  padding: 0 40px;
  background-color: var(--lightestgreen);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__top {
  padding-top: 115px;
}

.about__img {
  width: 100%;
  max-width: var(--container);
  height: 500px;
  object-fit: cover;
  border-radius: 10px;
}

.about__title {
  position: absolute;
  top: var(--fullscreenTop);
  padding: 100px 80px;
  background-color: rgb(251, 253, 225, 0.7);
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.about__title > p {
  font-size: 20px;
  font-weight: 600;
  padding-top: 5px;
}

.about__info {
  width: 860px;
  background-color: white;
  border-radius: 10px;
  border: 10px solid var(--beige);
  margin-top: -80px;
  line-height: 1.3;
}

.about__infoTop {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 60px 60px 0 60px;
}

.about__infoTopImg {
  width: 250px;
  border-radius: 10px;
  margin-left: 40px;
}

.about__infoImgSm {
  display: none;
  height: 200px;
  width: auto;
  border-radius: 10px;
  margin-bottom: 50px;
}

.about__infoBottom {
  padding: 20px 60px 40px 60px;
}

.about__button {
  padding-top: 100px;
  padding-bottom: 150px;
}

@media (max-width: 1350px) {
  .about__title {
    padding: 80px 60px;
  }
}

@media (max-width: 1050px) {
  .about__top {
    padding-top: 95px;
  }
  .about__title {
    top: var(--top1050);
  }
  .about__info {
    width: 720px;
  }

  .about__infoTopImg {
    display: none;
  }

  .about__infoImgSm {
    display: flex;
  }

  .about__infoTop {
    flex-direction: column;
    align-items: center;
    padding: 50px 60px 0 60px;
  }
  .about__infoBottom {
    padding: 0px 60px 40px 60px;
  }
}

@media (max-width: 915px) {
  .about__info {
    width: 640px;
  }
  .about__infoTop {
    padding: 40px 40px 0 40px;
  }
  .about__infoBottom {
    padding: 0px 40px 40px 40px;
  }
}

@media (max-width: 800px) {
  .about__info {
    width: 440px;
  }
  .about__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .about__title {
    position: relative;
    padding: 40px;
    margin-top: var(--marginTop800);
    margin-bottom: var(--marginBottom800);
    border-radius: 10px;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 60%;
  }
}

@media (max-width: 600px) {
  .about__info {
    width: 85%;
  }

  .about {
    padding: 0;
  }

  .about__top {
    padding-top: 75px;
  }

  .about__img {
    border-radius: 0px;
  }
  .about__title {
    padding: 40px 20px;
    min-width: 70%;
  }

  .about__infoTop {
    padding: 40px 30px 0 30px;
  }
  .about__infoBottom {
    padding: 0px 30px 40px 30px;
  }
}

@media (max-width: 450px) {
  .about__title {
    padding: 40px 10px;
    min-width: 85%;
  }
  .about__infoTop {
    padding: 60px 20px 0 20px;
  }
  .about__infoBottom {
    padding: 0px 20px 40px 20px;
  }
  .about__infoImgSm {
    height: 170px;
  }
}

@media (max-width: 315px) {
  .about__title {
    padding: 40px 5px;
  }
}

.footer {
  background-color: var(--brown);
  color: var(--beige);
  padding: 5px 20px;
}

.footer__icons {
  display: flex;
  color: var(--beige);
  padding-left: 13px;
}

.footer__icon {
  padding: 5px;
  color: inherit;
  cursor: pointer;
}

.footer__icon:hover {
  color: var(--darkgreen);
}

.footer > p {
  font-size: 12px;
  padding-bottom: 10px;
  color: var(--beige);
}

.footer > div {
  font-size: 12px;
  color: var(--beige);
}

a {
  color: var(--beige);
  font-size: 12px;
}

a:hover {
  text-decoration: underline;
}

.footer__privacyAndTerms {
  padding: 10px 10px 10px 0;
  display: flex;
}

.concept {
  padding: 115px 40px 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--lightestgreen);
}

.concept__img {
  width: 100%;
  max-width: var(--container);
  height: 500px;
  object-fit: cover;
  object-position: center left;
  border-radius: 10px;
}

.concept__title {
  position: absolute;
  top: var(--fullscreenTop);
  padding: 100px 80px;
  background-color: rgb(251, 253, 225, 0.7);
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.concept__title > p {
  font-size: 20px;
  font-weight: 600;
  padding-top: 5px;
}

.concept__button {
  padding: 100px 0 150px 0;
}

@media (max-width: 1350px) {
  .concept__title {
    padding: 80px 60px;
  }
}

@media (max-width: 1050px) {
  .concept {
    padding-top: 95px;
  }
  .concept__title {
    top: var(--top1050);
  }
}

@media (max-width: 800px) {
  .concept__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .concept__title {
    position: relative;
    padding: 40px;
    margin-top: var(--marginTop800);
    margin-bottom: var(--marginBottom800);
    border-radius: 10px;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 60%;
  }
}

@media (max-width: 600px) {
  .concept {
    padding: 0;
  }

  .concept__top {
    padding-top: 75px;
  }

  .concept__img {
    object-position: bottom;
    border-radius: 0px;
  }
  .concept__title {
    padding: 40px 20px;
    min-width: 70%;
  }
}

@media (max-width: 450px) {
  .concept__title {
    padding: 40px 10px;
    min-width: 85%;
  }
}

@media (max-width: 315px) {
  .concept__title {
    padding: 40px 5px;
  }
}

.fourSteps {
  width: 860px;
  background-color: white;
  border: 10px solid var(--lightestgreen);
  border-radius: 10px;
  margin-top: -80px;
  padding-bottom: 80px;
  padding-top: 40px;
  overflow: hidden;
}

.fourSteps > p {
  text-align: center;
  padding: 60px 100px 60px 100px;
  line-height: 1.3;
}

.stepsItem__right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.stepsItem__left {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.stepsItem__imgOnLeft {
  position: relative;
  background-color: var(--lightestgreen);
  height: 330px;
  width: 330px;
  border-radius: 999px;
  margin-left: -50px;
  margin-right: 100px;
}

.stepsItem__imgOnRight {
  position: relative;
  background-color: var(--lightestgreen);
  height: 330px;
  width: 330px;
  border-radius: 999px;
  margin-right: -50px;
  margin-left: 100px;
}

.leafImg {
  width: 330px;
  padding: 50px;
  margin-left: -90px;
  margin-top: -30px;
}

.caterpillarImg {
  width: 335px;
  padding: 50px;
  margin-left: -70px;
  margin-top: -60px;
}

.cocoonImg {
  width: 290px;
  padding: 50px;
  margin-left: 0px;
  margin-top: -20px;
}

.butterflyImg {
  width: 315px;
  padding: 50px;
  margin-left: -110px;
  margin-top: -80px;
}

.stepsItem__text {
  padding: 40px;
  text-align: center;
  line-height: 1.3;
}

.stepsItem__text > p {
  padding-top: 12px;
}

.leafImgSm {
  display: none;
}
.caterpillarImgSm {
  display: none;
}
.cocoonImgSm {
  display: none;
}
.butterflyImgSm {
  display: none;
}

@media (max-width: 1050px) {
  .fourSteps {
    width: 760px;
    padding-bottom: 20px;
    padding-top: 0px;
  }

  .stepsItem__imgOnLeft {
    height: 280px;
    width: 280px;
    margin-left: -50px;
  }

  .stepsItem__imgOnRight {
    height: 280px;
    width: 280px;

    margin-right: -50px;
  }
  .leafImg {
    width: 270px;
    margin-left: -75px;
  }

  .caterpillarImg {
    width: 280px;
  }

  .cocoonImg {
    width: 240px;
    padding: 50px;
  }

  .butterflyImg {
    width: 270px;
    padding: 70px;
    margin-top: -95px;
  }
}

@media (max-width: 915px) {
  .fourSteps {
    width: 640px;
  }

  .stepsItem__imgOnLeft {
    margin-right: 50px;
  }

  .stepsItem__imgOnRight {
    margin-left: 50px;
  }
}

@media (max-width: 800px) {
  .fourSteps {
    width: 480px;
  }

  .fourSteps > p {
    text-align: center;
    padding: 60px 40px 60px 40px;
    line-height: 1.3;
  }

  .stepsItem__imgOnLeft {
    height: 220px;
    width: 220px;
    margin-right: 0px;
    margin-left: -30px;
  }

  .stepsItem__imgOnRight {
    margin-left: 0;
    height: 220px;
    width: 220px;
    margin-right: -30px;
  }
  .leafImg {
    width: 220px;
    margin-left: -77px;
  }

  .caterpillarImg {
    width: 240px;
  }

  .cocoonImg {
    width: 200px;
    padding: 50px;
    margin-left: -20px;
    margin-top: -35px;
  }

  .butterflyImg {
    width: 215px;
    padding: 90px;
    margin-top: -105px;
    margin-left: -115px;
    overflow: hidden;
  }
}

@media (max-width: 550px) {
  .fourSteps {
    width: 85%;
  }

  .fourSteps > p {
    text-align: center;
    padding: 40px 30px 20px 30px;
  }

  .stepsItem__right {
    flex-direction: column;
  }

  .stepsItem__left {
    flex-direction: column;
  }

  .stepsItem__imgOnLeft {
    margin-left: 0px;
  }

  .stepsItem__imgOnRight {
    margin-right: 0px;
  }
  .leafImg {
    display: none;
  }

  .caterpillarImg {
    display: none;
  }

  .cocoonImg {
    display: none;
  }

  .butterflyImg {
    display: none;
  }

  .leafImgSm {
    display: flex;
    height: 130px;
    margin-left: 53px;
    margin-top: 55px;
  }
  .caterpillarImgSm {
    display: flex;
    height: 140px;
    margin-left: 40px;
    margin-top: 45px;
  }
  .cocoonImgSm {
    display: flex;
    height: 220px;
    margin-left: 10px;
    margin-top: 0px;
  }
  .butterflyImgSm {
    display: flex;
    height: 180px;
    margin-left: 20px;
    margin-top: 25px;
  }
}

@media (max-width: 350px) {
  .stepsItem__imgOnLeft,
  .stepsItem__imgOnRight {
    height: 200px;
    width: 200px;
  }

  .leafImgSm {
    height: 115px;
  }
  .caterpillarImgSm {
    display: flex;
    height: 130px;
    margin-left: 35px;
    margin-top: 40px;
  }
  .cocoonImgSm {
    height: 200px;
    margin-left: 6px;
  }
  .butterflyImgSm {
    height: 160px;
  }
}

.contact {
  background-color: var(--beige);
  padding-top: 115px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact__img {
  height: 500px;
  width: 90vw;
  max-width: var(--container);
  object-fit: cover;
  border-radius: 10px;
}

.contact__title {
  position: absolute;
  top: var(--fullscreenTop);
  padding: 100px;
  background-color: rgb(251, 253, 225, 0.7);
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.contact__title > p {
  font-size: 20px;
  font-weight: 600;
  padding-top: 5px;
}

@media (max-width: 1350px) {
  .contact__title {
    padding: 80px 60px;
  }
}

@media (max-width: 1050px) {
  .contact {
    padding-top: 95px;
  }
  .contact__title {
    top: var(--top1050);
  }
}

@media (max-width: 800px) {
  .contact__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .contact__title {
    position: relative;
    padding: 40px;
    margin-top: var(--marginTop800);
    margin-bottom: var(--contactMarginBottom800);
    border-radius: 10px;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 60%;
  }
}

@media (max-width: 600px) {
  .contact {
    padding: 0;
  }

  .contact__top {
    padding-top: 75px;
  }

  .contact__img {
    border-radius: 0px;
    width: unset;
  }
  .contact__title {
    padding: 40px 20px;
    min-width: 70%;
  }
}

@media (max-width: 450px) {
  .contact__title {
    padding: 40px 10px;
    min-width: 85%;
  }
}

@media (max-width: 315px) {
  .contact__title {
    padding: 40px 5px;
  }
}

.contactForm {
  width: 880px;
  background-color: white;
  margin-top: -80px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 60px 0;
}

.contactFormContainer {
  width: 500px;
}

.contactFormContainer > h2 {
  text-align: center;
  font-size: 35px;
}

.contactForm__text {
  text-align: center;
  padding: 15px 0;
}

.contactForm__warning {
  text-align: center;
  color: red;
  padding: 20px 0;
}

.contactForm__input {
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 18px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 16px;
}

.lightGreenBackground {
  background-color: var(--lightestgreen);
}

.greenBackground {
  background-color: var(--lightgreen);
}

.darkGreenBackground {
  background-color: var(--green);
  height: 130px;
  text-align: start;
}

.contactForm__disclaimer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0px 30px 0px;
}

.addPadding {
  padding-left: 10px;
}

.contactForm__confirmation {
  position: fixed;
  top: 40%;
}

.botCheck {
  text-align: center;
  padding: 20px 0;
}

.botCheck__images {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.botCheck__images > img {
  height: 80px;
  margin: 10px;
  cursor: pointer;
}

.botCheck__images > img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.botCheck__clicked {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  background-color: var(--green);
  border-radius: 10px;
}

@media (max-width: 1050px) {
  .contactForm {
    width: 720px;
  }

  .contactFormContainer > h2 {
    font-size: 30px;
  }
}

@media (max-width: 915px) {
  .contactForm {
    width: 640px;
    padding: 50px 0;
  }

  .contactFormContainer {
    width: 400px;
  }

  .contactFormContainer > h2 {
    font-size: 25px;
  }

  .contactForm__text {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .contactForm__input {
    padding: 15px 5%;
    width: 90%;
  }
}

@media (max-width: 800px) {
  .contactForm {
    width: 480px;
    padding: 40px 0;
    margin-top: 20px;
  }

  .contactFormContainer {
    width: 380px;
  }
}

@media (max-width: 550px) {
  .contactForm {
    width: 90%;
    padding: 40px 0;
    margin-top: 20px;
  }

  .contactFormContainer {
    width: 85%;
  }

  .botCheck__images > img {
    height: 60px;
  }
}

.confirmation {
  background-color: rgb(255, 255, 255, 0.9) !important;
  padding: 30px 40px;
  width: 300px;
  text-align: center;
  border-radius: 10px;
}

.confirmation > p {
  padding: 20px 0;
}

.confirmation > button {
  border: 1.5px solid var(--brown);
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  padding: 5px 20px;
  background-color: transparent;
  font-size: 14px;
  color: var(--brown);
  border-radius: 10px;
}

.confirmation > button:hover {
  background-color: var(--brown);
  color: white;
  transition: all 0.3s;
}

.faq {
  background-color: var(--beige);
  padding-top: 115px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq__img {
  height: 500px;
  width: 90vw;
  max-width: var(--container);
  object-fit: cover;
  border-radius: 10px;
}

.faq__title {
  position: absolute;
  top: var(--fullscreenTop);
  padding: 100px 100px;
  background-color: rgb(251, 253, 225, 0.7);
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.faq__title > p {
  font-size: 20px;
  font-weight: 600;
  padding-top: 5px;
}

@media (max-width: 1350px) {
  .faq__title {
    padding: 90px 60px;
  }
}

@media (max-width: 1050px) {
  .faq {
    padding-top: 95px;
  }
  .faq__title {
    top: var(--top1050);
  }
}

@media (max-width: 800px) {
  .faq__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .faq__title {
    position: relative;
    padding: 55px 40px;
    margin-top: var(--marginTop800);
    margin-bottom: var(--marginBottom800);
    border-radius: 10px;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 60%;
  }
}

@media (max-width: 600px) {
  .faq {
    padding: 0;
  }

  .faq__top {
    padding-top: 75px;
  }

  .faq__img {
    border-radius: 0px;
    width: unset;
  }
  .faq__title {
    padding: 55px 20px;
    min-width: 70%;
  }
}

@media (max-width: 450px) {
  .faq__title {
    padding: 55px 10px;
    min-width: 85%;
  }
}

@media (max-width: 315px) {
  .faq__title {
    padding: 55px 5px;
  }
}

.questions {
  width: 860px;
  background-color: white;
  border-radius: 10px;
  border: 10px solid var(--lightestgreen);
  margin-top: -80px;
  line-height: 1.3;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  flex-direction: column;
}

.question {
  padding: 25px 50px;
}

.question__question {
  cursor: pointer;
}

.question__question:hover {
  color: var(--lightbrown);
  transition: all 0.3s;
}

.noAnswer {
  line-height: 0;
  overflow: hidden;
  transition: all 0.3s;
}

.noAnswer > ul {
  height: 0;
}

.noAnswer > p > ul > li {
  height: 0;
}

.question__answer {
  transition: all 0.3s;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 10px;
  padding-bottom: 10px;
}

.question__listItem::marker {
  font-family: "Dekko", cursive;
  font-weight: 300;
  font-size: 22px;
  line-height: 1.3px;
}

@media (max-width: 1050px) {
  .questions {
    width: 720px;
  }

  .question__listItem::marker {
    font-size: 21px;
  }
}

@media (max-width: 915px) {
  .questions {
    width: 640px;
  }
}

@media (max-width: 800px) {
  .questions {
    width: 440px;
  }
}

@media (max-width: 600px) {
  .questions {
    width: 75%;
    margin-bottom: 100px;
  }
}

.admin__header {
  display: flex;
  height: 60px;
  justify-content: space-around;
  align-items: center;
  background-color: var(--beige);
}

.admin__option {
  cursor: pointer;
}

.admin__option:hover {
  color: var(--darkgreen);
  transition: all 0.3s;
}

.admin__body {
  background-color: var(--lightestgreen);
  padding: 50px 0;
  min-height: 100%;
}

.adminAddNew {
  font-size: 20px;
  text-align: center;
  cursor: pointer;
}

.adminTitle {
  text-align: center;
  padding-bottom: 20px;
}

.adminItemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.adminItemTitle {
  padding-bottom: 10px;
}

.adminItem {
  width: 85%;
  max-width: 600px;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  background-color: var(--green);
}

.adminButtons {
  display: flex;
}

.adminEdit,
.adminDelete,
.adminButton {
  background-color: var(--lightbrown);
  color: white;
  padding: 3px 10px;
  margin: 10px 20px 0 0;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.adminLang {
  background-color: var(--lightbrown);
  color: white;
  padding: 3px 10px;
  margin: 10px 20px 20px 0;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.adminAddNewQuestion {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.adminInput {
  width: 85%;
  max-width: 600px;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  background-color: white;
}

.adminInput > textarea {
  outline: none;
  border: none;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 10px;
  font-size: 16px;
  min-height: 400px;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--beige);
  min-height: 100vh;
}

.login__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.login__email,
.login__password {
  width: 250px;
  height: 50px;
  outline: none;
  border-radius: 10px;
  border: none;
  margin-bottom: 20px;
  padding: 0 20px;
  font-size: 18px;
}

.login__email {
  background-color: var(--darkgreen);
}

.login__password {
  background-color: var(--darkgreen);
}

.login__form > button {
  background-image: linear-gradient(
    to top,
    var(--darkgreen) 35%,
    var(--yellow) 100%,
    var(--darkgreen) 100%
  );
}
.login__form > button {
  width: 150px;
  border: none;
  outline: none;
  border-radius: 68px;
  padding: 12px 0;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  display: block;
  cursor: pointer;
  margin-top: 10px;
}

.login__form > button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.promoBar {
  text-align: center;
  padding: 16px 0;
  background-color: var(--darkgreen);
  cursor: pointer;
}

.promoBar p {
  font-size: 22px;
  color: white;
}

